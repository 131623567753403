<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <quote-list-side-menu v-if="!!jobStages?.length"
          v-model="filter"
          :job-stages="jobStages"
          :reset-values="resetValuesObj"
          :include-claims-type="includeClaimsType"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <!-- <div class="tile is-parent animated lightSpeedIn" v-show="toggle"> -->
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.quoteId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <router-link :to="{ path: entity.quoteId }"
                    append>
                    {{ entity.quoteNoRef }}
                  </router-link>
                </td>
                <td>{{ $filters.formatDateTimezone(entity.quoteDate, $userInfo.locale) }}</td>
                <td>{{ entity.regoNo }}</td>
                <td>{{ entity.makeModel }}</td>
                <td>{{ entity.claimNo }}</td>
                <td v-if="includeClaimsType">{{ entity.claimsTypeKey }}</td>
                <td>{{ entity.customerName }}</td>
                <td>{{ entity.insurerName }}</td>
                <td>{{ $filters.formatDateTimezone(entity.authorisedDate, $userInfo.locale) }}</td>
                <!-- <td>{{entity.assessorName}}</td> -->
                <td class="is-email">
                  <div>{{ entity.quoteStatus }}</div>
                </td>
                <td>
                  {{ entity.jobStageText }}
                </td>
                <td class="has-text-centered">
                  <span class="tag"
                    :class="[ entity.quotingMethod === 'D' ? 'is-success' : 'is-info' ]">{{ entity.quotingMethod }}</span>
                </td>
                <td class="has-text-centered">
                  <span
                    class="icon"
                    :class="[ entity.invoiceStatus === 1 ? 'has-text-warning' : 'has-text-success' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.invoiceStatus > 0 ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ entity.excessInvoiced ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.excessInvoiced ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ entity.active ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.active ? 'mdi-check' : 'mdi-close' ]" />
                  </span>
                </td>
                <td class="has-text-centered">
                  <router-link :to="{ path: entity.quoteId }"
                    append>
                    <a class="button is-primary is-small is-inverted tooltip"
                      data-tooltip="Click to edit">
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </a>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
            <!-- <img :src="imagePath" /> -->
            <!-- <object :data="pdfFrameData"
              height="500"
              style="width: 100%"></object> -->
          </article>
        </div>
      </div>
    </div>
    <quote-list-print-modal v-if="isPrintQuoteListModalActive"
      :active.sync="isPrintQuoteListModalActive"
      @print="printQuoteList"
      @export="exportToExcel"
      @close="closePrint" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import QuoteListSideMenu from './QuoteListSideMenu'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import Guid from '@/components/Guid'
import { AppHeaderButtonTypes } from '@/enums'
import QuoteService from './QuoteService'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import PrintPreviewService from '@/components/printpreview/PrintPreviewService'
import { QuoteListPrintModal } from './components'
import _transform from 'lodash/transform'
import { KeyValuePairModel } from '@/classes/viewmodels'
import cloneDeep from 'lodash.clonedeep'
import { TableColumn } from '../../classes'

export default {
  name: 'QuoteListView',
  components: {
    AppDetailHeader,
    QuoteListSideMenu,
    BulmaTable,
    QuoteListPrintModal
  },
  mixins: [StoreMixin, DateTimeFiltersMixin],
  props: {},
  data() {
    return {
      entitySummaries: null,
      totalRows: 0,
      filter: {
        search: '',
        dateFrom: '',
        dateTo: '',
        insurerId: Guid.empty(),
        insurerName: '',
        jobStage: [],
        active: 1,
        invoiceStatus: 'A',
        authorisedStatus: 'B',
        quoteType: 'Q',
        claimsTypeKey: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 12
      },
      resetValuesObj: {
        search: '',
        dateFrom: '',
        dateTo: '',
        insurerId: Guid.empty(),
        insurerName: '',
        jobStage: [],
        active: 1,
        invoiceStatus: 'A',
        authorisedStatus: 'B',
        quoteType: 'Q',
        claimsTypeKey: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 12
      },
      selectedRow: null,
      isTableLoading: false,
      pdfFrameData: '',
      isPrintQuoteListModalActive: false
      // imagePath: ''
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      if (this.includeClaimsType) {
        let clonedColumns = cloneDeep(Columns)
        /// add claims type columm after claim no
        clonedColumns.splice(5, 0, new TableColumn('Claims Type.', 'claimsTypeKey', true, 'desc', 'left'))
        return clonedColumns
      }
      return Columns
    },
    includeClaimsType() {
      //TODO: change to my afer devlopment
      return this.$company && this.$company.info && this.$company.info.countryCode === 'MY'
    }
  },
  created() {
    this.getData()
    // OpenImage('A4C889F2-491F-46EF-B8B4-0000617EAF6A')
    // this.imagePath = await QuoteService.getImage('A4C889F2-491F-46EF-B8B4-0000617EAF6A')
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          // this.testConnection('ormtrans0227', 'ttMaMvziikiaJCb')
          // this.testConnection('ormtrans0547', 'TxwKH6Tx7YPGkfr')
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    resetList() {
      // have to use this method to reset the filter becaause the
      // reset object was mutating the filter object
      this.filter = cloneDeep(this.resetValuesObj)

      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      // covert array of jobstages object to array of stage ids for api
      const apifilters = this.filter
      if (!!apifilters.jobStage.length) {
        apifilters.jobStage = apifilters.jobStage.map((stage) => {
          return stage.stage ? stage.stage : stage
        })
      }

      this.entitySummaries = await QuoteService.getEntitySummaries(apifilters)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async print() {
      this.isPrintQuoteListModalActive = true
    },
    closePrint() {
      this.isPrintQuoteListModalActive = false
    },
    generateReportParams: function (report) {
      var isAuthorised = 'BOTH'
      var isInvoiced = 'BOTH'
      var isActive = 'BOTH'
      var allDate = 'NN'

      if (this.filter.dateFrom == '') {
        allDate = 'Y'
      } else {
        allDate = 'N'
      }

      if (this.filter.dateTo == '') {
        allDate = allDate + 'Y'
      } else {
        allDate = allDate + 'N'
      }

      switch (this.filter.authorisedStatus) {
        case 'B':
          isAuthorised = 'BOTH'
          break
        case 'A':
          isAuthorised = 'YES'
          break
        case 'U':
          isAuthorised = 'NO'
          break
      }

      switch (this.filter.invoiceStatus) {
        case 'A':
          isInvoiced = null
          break
        case 'I':
          isInvoiced = 'YES'
          break
        case 'U':
          isInvoiced = 'NO'
          break
        case 'P':
          isInvoiced = 'Partially'
          break
        default:
          isInvoiced = null
      }

      switch (this.filter.active) {
        case 0:
          isActive = 'BOTH'
          break
        case 1:
          isActive = 'YES'
          break
        case 2:
          isActive = 'NO'
          break
      }

      let params = Object.assign({
        fromquotesdate: this.filter.dateFrom,
        endquotesdate: this.filter.dateTo,
        insurerId: this.filter.insurerId != '00000000-0000-0000-0000-000000000000' ? this.filter.insurerId : 0,
        insurerName: this.filter.insurerName,
        jobStageId: this.filter.jobStage.join(','),
        isAuthorised: isAuthorised,
        isInvoiced: isInvoiced,
        companyId: this.$userInfo.companyId,
        quoteType: this.filter.quoteType,
        timezoneoffset: this.$filters.getSiteTimezoneOffset(),
        sortColumn: this.filter.sortColumn,
        sortDirection: this.filter.sortOrder,
        allDate: allDate,
        search: this.filter.search,
        claimsType: this.filter.claimsTypeKey
      })

      if (report == 'QuoteAnalysis' || report == 'QuoteAnalysisExcel') {
        params.isActive = isActive
      }

      params = _transform(params, function (result, value, key) {
        result[key.toLowerCase()] = value
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      return keyValuePairs
    },

    async exportToExcel(format) {
      const keyValuePairs = this.generateReportParams('QuoteAnalysisExcel')
      await PrintPreviewService.exportReportEx('rptQuoteAnalysisExcel', format, keyValuePairs)
      this.isPrintQuoteListModalActive = false
    },
    async printQuoteList(report) {
      const keyValuePairs = this.generateReportParams(report)
      this.addReportParameters(keyValuePairs)

      var reportName = ''
      var emailSubject = ''
      if (report == 'QuoteAnalysis') {
        reportName = 'rptQuoteAnalysis'
        emailSubject = 'Quote Analysis'
      } else if (report == 'QuoteAnalysisExcel') {
        reportName = 'rptQuoteAnalysisExcel'
        emailSubject = 'Quote Analysis Additional Columns'
      } else {
        reportName = 'rptStrikeRate'
        emailSubject = ' Strike Rate'
      }
      const emailer = new Emailer()
      emailer.subject = emailSubject
      emailer.reportName = reportName
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: reportName },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
      this.isPrintQuoteListModalActive = false
    },
    async testConnection(username, password) {
      const id = await QuoteService.testConnection(username, password)
      console.log(username, id)
    },
    async addNew() {
      // this.getNewVehicle()
      // this.getNewCustomer()
      // this.getNewInsurer()
      this.addStoreItem(0, false)
    }
  }
}
</script>
